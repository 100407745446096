import React from "react";
import "./Footer.css";
import haertIcon from "../../Assests/img/heart-icon.webp";
const Footer = () => {
  return (
    <>
      <footer className="container-fluid">
        <div className="container">
          <div className="footer-inner">
            <h1>
              Want to have a lasting impression on your audience?<br></br>
              Let’s work together.
            </h1>
            <div className="row footer-top-spacing">
              <div className="col-md-6 col-sm-12 col-lg-8">
                <a href="/our-work">
                  {" "}
                  <button className="common-btn">our work</button>
                </a>
              </div>
              <div className="col-md-6 col-sm-12 col-lg-4 footer-link">
                <p>Looking to know a company with flair and tenacity?</p>
                <ul>
                  <li>
                    <a href="/hire-us">HIRE US</a>
                  </li>
                  <li>
                    <a href="/about-us">ABOUT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="copright">
            <ul>
              <li>
                Made with{" "}
                <img src={haertIcon} alt="haertIcon" title="haertIcon"></img> by
                LeapWide
              </li>
              <li>
                <a href="/contact-us">Contact</a>
              </li>
              <li>
                <a href="/privacy-policies" target="_blank">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;

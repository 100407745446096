import React from 'react'
import { Helmet } from "react-helmet";
import HarryBooneLine from "../../Assests/img/HarryBoone/HarryBooneLine.webp";
import HarryBooneColorLine from "../../Assests/img/HarryBoone/HarryBooneColorLine.webp";
import HarryBoone3 from "../../Assests/img/HarryBoone/HarryBoone3.png";
import HarryBoone4 from "../../Assests/img/HarryBoone/HarryBoone4.webp";
import HarryBoone5 from "../../Assests/img/HarryBoone/HarryBoone5.webp";
import HarryBooneMockup from "../../Assests/img/HarryBoone/HarryBooneMockup.webp";
import HarryBooneImg from "../../Assests/img/HarryBoone/HarryBoone.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const HarryBoone = () => {
    return (
        <>
            <Helmet>
                <title>Artist Website | Painter Website | LeapWide</title>
                <meta name="description" content="Explore Leapwide’s custom design and development for Harry Boone’s paintings portfolio, featuring a visually captivating and user-friendly website." />
            </Helmet>
            <div className='top-spacing container-fluid HarryBoone spacing-fromheader' id='HarryBoone'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={HarryBooneImg} alt='HarryBooneImg' title='HarryBooneImg'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>Harry Boone</span>
                                </h1>
                                <p className='para-txt'>Harry Boone is an artist who grew up in a creative family. After studying philosophy, he discovered his passion for visual arts, combining intellect and hands-on work to create his art.
                                </p>
                            </div>
                        </div>
                    </div>
                    <a href='https://harryboone.com/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />www.HarryBoone.com</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing'>
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 Artistic-Universe'>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-md-7'>
                                                <h3>
                                                    <span>
                                                        Unveiling Harry Boone's Artistic Universe
                                                    </span>
                                                </h3>
                                                <h6 className='overview'>
                                                    Research</h6>
                                                <p className='para-txt'>To create a website that captured the essence of Boone’s artistic journey, we embarked on a deep dive into his world. Through extensive research and collaboration, we developed a compelling brand story that highlighted his unique perspective and creative process. This narrative served as the foundation for a visual identity that reflected his artistic style and resonated with his target audience.
                                                </p>
                                                <h6 className='overview history'>
                                                    Clean Responsive Design
                                                </h6>
                                                <p className='para-txt'>The design & development process involved creating a clean and modern interface that is both visually appealing and easy to navigate. Our team carefully curated the layout to highlight Boone’s work, ensuring that each piece is presented in a way that resonates with the viewer. The responsive design ensures that the website functions flawlessly across all devices, providing a seamless experience whether accessed on a desktop, tablet, or smartphone.
                                                </p>
                                            </div>
                                            <div className='col-md-5  work-we-did'>
                                                <h6>WHAT WE DID</h6>
                                                <ul>
                                                    <li>UX + VISUAL DESIGN</li>
                                                    <li>VISUAL BRANDING</li>
                                                    <li>WEBSITE DESIGN & DEVELOPMENT</li>
                                                    <li>WEB ANALYTICS</li>
                                                    <li>STRATEGIC BRAND STORY</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={HarryBooneLine} alt='HarryBooneLine' title='HarryBooneLine'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
              
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='mobileLabour' data-aos="fade-up" data-aos-delay="5">
                    <img src={HarryBoone3} alt='HarryBoone3' title='HarryBoone3'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>BEAUTIFUL BUT ALSO HIGHLY FUNCTIONAL</h6>
                            <h3>Crafting an Immersive Digital Experience</h3>
                            <p>Our UX and visual design team worked closely with Harry Boone to create a website that offered an immersive and engaging user experience. We focused on creating a clean, intuitive interface that allowed visitors to effortlessly explore Harry Boone's artwork and learn about his artistic process. By conducting thorough user research and usability testing, we optimized the website's information architecture and navigation to ensure a seamless user journey. Our designers created visually stunning layouts and compositions that showcased Harry Boone's artwork in the best possible light. We paid close attention to typography, color, and imagery to create a cohesive and visually appealing website.
                            </p>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={HarryBooneMockup} alt='HarryBooneMockup' title='HarryBooneMockup'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                  {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line harrayboone-colorimg' data-aos="fade-up" data-aos-delay="5">
                    <img src={HarryBooneColorLine} alt='HarryBooneColorLine' title='HarryBooneColorLine'></img>
                </div>
                {/* COLOR LINE END */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="5">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>DIGITAL PORTFOLIO - EXTENSION OF HIS ARTISTIC IDENTITY</h6>
                                <h3>Technical Excellence Meets Artistic Vision</h3>
                                <p>Our project management team meticulously planned and executed the website development process, ensuring that the project stayed on schedule and within budget. Our front-end and back-end developers worked tirelessly to bring the design to life, creating a robust and scalable website that could handle high traffic and deliver exceptional performance. To measure the website's success and identify areas for improvement, we implemented comprehensive web analytics.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={HarryBoone4} alt='HarryBoone4' title='HarryBoone4'></img>

                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}
                {/* ------------------------/ */}

                {/* REPLICA START */}
                <div className='replica'>
                    <img src={HarryBoone5} alt='HarryBoone5' title='HarryBoone5'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial' data-aos="fade-up" data-aos-delay="5">
                    <h2>“ The team truly understood my artistic vision and translated it into a stunning digital experience. I’m were impressed by their attention to detail, their expertise in UX design, and their ability to meet deadlines without compromising quality. I highly recommend LeapWide to any artist looking to create a captivating online presence.”</h2>
                    <p className='testimonial-source'>– Harry Walton Boone, Artist</p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default HarryBoone

import React from 'react';
import { Helmet } from "react-helmet";
import LivingStandardimg from "../../Assests//img/LivingStandard/client-thumb-template-wide.webp";
import LivingStandardTitle from "../../Assests/img/LivingStandard/LivingStandardTitle.webp";
import iMacMockup from "../../Assests/img/LivingStandard/iMac_Mockup.webp";
import livingStandardFullwidth from "../../Assests/img/living-standard-fullwidth-color.webp";
import livingStandardFans from "../../Assests/img/LivingStandard/living-standard-fans.webp";
import livingStandardMobile from "../../Assests/img/LivingStandard/livingStandardMobile.webp";
import livingStandardHomepage from "../../Assests/img/LivingStandard/livingStandardHomepage.webp";
import livingStandardGif from "../../Assests/img/LivingStandard/ezgif.com-optimize.gif";
import livingStandardGirls from "../../Assests/img/LivingStandard/living-standard-girls.webp"
import livingStandardstandingLady from "../../Assests/img/LivingStandard/living-standardstandinglady.webp";

import { LiaLongArrowAltRightSolid } from "react-icons/lia";
const LivingStandard = () => {
    return (
        <>
            <Helmet>
                <title>Nonprofit Website Development | Engaging & User-Friendly Design | LeapWide</title>
                <meta name="description" content="Explore how Leapwide delivered a user-friendly and engaging website for Living Standard, focusing on nonprofit goals with customized web design and development." />
            </Helmet>
            <div className='top-spacing container-fluid spacing-fromheader' id='living-standard'>
                <div className='container-fluid labour-party-banner'>
                    <div className='row'>
                        <div className='col-md-6 inner-img-banner'>
                            <img src={LivingStandardimg} alt='LivingStandardimg' title='LivingStandardimg'></img></div>
                        <div className='col-md-6 labour-txt'>
                            <div className='inner-content-banner'>
                                <h1 class="border-head">
                                    <span>Living Standard</span>
                                </h1>
                                <p className='para-txt'>True to its name, Living Standards is a US Green Building Council project that aims to improve the standard of living for people all over the globe. The initiative is full of stories of ordinary people doing extraordinary work and making the world a better place.</p>
                            </div>
                        </div>
                        {/* <div className='col-md-1'>
                            <div class="arrow"><a href="#featured-products"></a></div>
                        </div> */}
                    </div>
                    <a href='https://livingstandard.org/' target='_blank' rel="noreferrer" className='for-scale'><button class="firstbtn firstbtn-2 hover-slide-right">
                        <span><LiaLongArrowAltRightSolid />livingstandard.org</span>
                    </button></a>
                </div>
                {/*MODERNIZING START*/}
                <div className='Modernizing' data-aos="fade-up" data-aos-delay="10">
                    <div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-md-11 '>
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-lg-7 col-md-12'>
                                                <h3>
                                                    <span>
                                                        A Kind New Perspective
                                                    </span>
                                                </h3>
                                                <h6 className='overview'>
                                                    Overview</h6>
                                                <p className='para-txt'>Living Standard looked to collaborate with a digital agency that understood how important it was to create a powerful online identity to convey the unique work they were doing. LeapWide worked with the Living Standard team to conceptualize an art direction that provided a smooth transition for their current digital presence.
                                                    <br></br><br></br>
                                                    Living Standard had an impressive vision and needed a novel way to catch the attention they deserved. As a new initiative with little content, we had a clear starting point. How do we create a landing page with the flexibility to grow with the organization using a captivating story that pulls the readers? Following extensive creative brainstorming and user experience testing, we created an interactive page with a vivid flow, to not only draw readers in but compel them to sign up
                                                </p>
                                            </div>
                                            <div className='col-lg-5 col-md-7  work-we-did'>
                                                <h6>WORK WE DID</h6>
                                                <ul>
                                                    <li>UX & UI</li>
                                                    <li>DESIGN & IDEATION</li>
                                                    <li>CONTENT STRATEGY</li>
                                                    <li>BRANDING & IDENTITY</li>
                                                    <li>CRM</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container' data-aos="fade-up" data-aos-delay="10">
                                        <div className='row'>
                                            <div class="video-wrapper" data-reactid="158">

                                                <div class="mobile-image" data-reactid="163"><img src={iMacMockup} title='iMacMockup' alt='iMacMockup' /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-1 rotate-img'>
                                    <img src={LivingStandardTitle} alt='LivingStandardTitle' title='LivingStandardTitle'></img>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/*MODERNIZING END*/}
                {/* --------------------- */}

                {/* COLOR LINE START */}
                <div className='color-line' data-aos="fade-up" data-aos-delay="10">
                    <img src={livingStandardFullwidth} alt='livingStandardFullwidth' title='livingStandardFullwidth'></img>
                </div>
                {/* COLOR LINE END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='livingStandardFans' data-aos="fade-up" data-aos-delay="10">
                    <img src={livingStandardFans} alt='livingStandardFans' title='livingStandardFans'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}
                {/* MOBILE LABOUR START */}
                <div className='mobileLabour' data-aos="fade-up" data-aos-delay="10">
                    <img src={livingStandardMobile} alt='livingStandardMobile' title='livingStandardMobile'></img>
                </div>
                {/* MOBILE LABOUR END */}
                {/* --------------------- */}

                {/* ------------------ */}
                {/* RELAUCH START */}
                <div className='container-fluid relaunch'>
                    <div className='row'>
                        <div className='col-md-6 relaunch-txt'>
                            <h6>LAUNCH AT THE RIGHT TIME</h6>
                            <h3>Stunningly Responsive</h3>
                            <p>Living Standard’s website was launched in two phases. The first phase corresponded with the Greenbuild International Conference and Expo and was aimed at informing visitors about the organization through an interactive landing page. In the second phase, stories from intellectuals and experts were added along with broadening the scope of the website’s information pages.
                            </p>
                            <ul>
                                <li>Two Phased website launch</li>
                                <li>Animated graphic elements</li>
                                <li>HTML5 Video</li>
                                <li>Advanced motion graphics</li>
                            </ul>
                        </div>
                        <div className='col-md-6 Screencapture'>
                            <div className='ScreencaptureImg'>
                                <img src={livingStandardHomepage} alt='livingStandardHomepage' title='livingStandardHomepage'></img>
                            </div>
                        </div>
                    </div>
                </div>
                {/* RELAUCH END */}
                {/* BEGINNING START  */}
                <div className='container' id='beginning' data-aos="fade-up" data-aos-delay="10">
                    <div className='row'>
                        <div className='col-md-12 col-lg-6'>
                            <div className='content-left'>
                                <h6>SPLENDID VISUALS</h6>
                                <h3>Storytelling through pictures</h3>
                                <p>The website featured a striking use of images to add depth to the storytelling. The development team was tasked with creating an unravelling effect for the content as the visitors scrolled through the site. We built the entire structure of the website in such a way that it emphasizes the interactivity and engagement with the brand. Hedingham was keen on having a technically sorted content system with a short learning curve to enable easy use for management and staff. We decided on WordPress as the platform of choice and integrated it with a ticketing system, customer support software and an events calendar.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-12 col-lg-6'>
                            <img src={livingStandardGif} alt='livingStandardGif' title='livingStandardGif'></img>

                        </div>
                    </div>
                </div>
                {/* BEGINNING END  */}
                {/* ------------------------/ */}
                {/* TENT PATTERN START */}
                <div className='tent-pattern' data-aos="fade-up" data-aos-delay="10">
                    <img src={livingStandardGirls} alt='livingStandardGirls' title='livingStandardGirls'></img>
                </div>
                {/* TENT PATTERN END */}
                {/* ---------------------------- */}
                {/* REPLICA START */}
                <div className='replica'>
                    <img src={livingStandardstandingLady} alt='livingStandardstandingLady' title='livingStandardstandingLady'></img>
                </div>
                {/* REPLICA END */}
                {/* ---------------------------- */}
                {/* TESTIMONIAL START */}
                <div className='testimonial'>
                    <h2>“The LeapWide team has been monumental in helping us develop a brand voice and identity. The creative work they have done for our website has immensely helped us in our endeavor to engage audiences deeply with our mission. We have a long road ahead of us and thanks to LeapWide’s brilliant work, we have a strong dynamic presence to carry us forward.”</h2>
                    <p className='testimonial-source'>–Rivers Patton, U.S. Green Building Council</p>
                </div>
                {/* TESTIMONIAL END */}
            </div>
        </>
    )
}

export default LivingStandard

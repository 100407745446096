import React from "react";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Leapwide Digital Solutions</title>
        <meta
          name="description"
          content="Review Leapwide’s privacy policies to understand how we protect your data while providing web development, mobile app development, and software solutions."
        />
      </Helmet>
      <div
        className="top-spacing container-fluid spacing-fromheader"
        id="privacypolicies"
      >
        <div className="container">
          <h1>
            <span>Privacy Policy</span>
          </h1>
          <p>Effective As Of: May 21, 2018</p>
          <p>
            At LeapWide.com, accessible from https://leapwide.com/, one of our
            main priorities is the privacy of our visitors. This Privacy Policy
            document contains types of information that is collected and
            recorded by LeapWide.com and how we use it.
            <br></br>
            <br></br>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us.
          </p>
          <h5>1. Log Files</h5>
          <p>
            LeapWide.com follows a standard procedure of using log files. These
            files log visitors when they visit websites. All hosting companies
            do this and a part of hosting services' analytics. The information
            collected by log files include internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </p>
          <h5>2. Cookies and Web Beacons</h5>
          <p>
            Like any other website, LeapWide.com uses 'cookies'. These cookies
            are used to store information including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. The
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </p>
          <h5>3. Privacy Policies</h5>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of LeapWide.com. Third-party ad servers or ad
            networks uses technologies like cookies, JavaScript, or Web Beacons
            that are used in their respective advertisements and links that
            appear on LeapWide.com, which are sent directly to users' browser.
            They automatically receive your IP address when this occurs. These
            technologies are used to measure the effectiveness of their
            advertising campaigns and/or to personalize the advertising content
            that you see on websites that you visit.<br></br>
            <br></br>
            Note that LeapWide.com has no access to or control over these
            cookies that are used by third-party advertisers.
          </p>
          <h5>4. Third-Party Privacy Policies</h5>
          <p>
            LeapWide.com's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. It may include their practices and instructions about
            how to opt-out of certain options. You may find a complete list of
            these Privacy Policies and their links here: Privacy Policy Links.
            <br></br>
            <br></br>
            You can choose to disable cookies through your individual browser
            options. To know more detailed information about cookie management
            with specific web browsers, it can be found at the browsers'
            respective websites. What Are Cookies?
          </p>
          <h5>5. Children's Information</h5>
          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
            <br></br>
            <br></br>
            LeapWide.com does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best efforts to promptly remove such information from our records.
          </p>
          <h5>6. Online Privacy Policy Only</h5>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in LeapWide.com. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
          <h5>7. Consent</h5>
          <p>
            By using our website, you hereby consent to our Privacy Policy and
            agree to its Terms and Conditions.
            <br></br>
            <br></br>
            <span>
              If you have questions, comments, or complaints about this Privacy
              Policy or our privacy practices or if you would like to exercise
              your rights and choices, please email us at{" "}
              <a href="mailto:hello@leapwide.com" target="_blank">
                hello@leapwide.com
              </a>
              .
            </span>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

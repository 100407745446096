import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, useNavigate, useLocation } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Preloader from './Components/Loading/Preloader'; // Import the Preloader
const root = ReactDOM.createRoot(document.getElementById('root'));

const ScrollToTop = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(location.pathname, { replace: true });
    window.scrollTo(0, 0);
  }, [location.pathname, navigate]);

  return null;
};

const RootComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Check if the preloader has already been shown in this session
    const preloaderShown = sessionStorage.getItem('preloaderShown');

    if (!preloaderShown) {
      // Simulate an API call or a timeout to simulate loading
      const timer = setTimeout(() => {
        setLoading(false);
        // Mark that the preloader has been shown
        sessionStorage.setItem('preloaderShown', 'true');
      }, 2000); // Adjust timing based on your needs

      return () => clearTimeout(timer);
    } else {
      // If the preloader has been shown, skip loading
      setLoading(false);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Preloader />  
      ) : (   

        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
       )} 
    </>
  );
};

root.render(
  <React.StrictMode>
    <RootComponent />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
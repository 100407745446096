import React from 'react';
import { Helmet } from "react-helmet";
import "./About.css";
const About = () => {
    return (
        <>
            <Helmet>
                <title>About Leapwide | Your Trusted Digital Solutions Partner</title>
                <meta name="description" content="Learn about Leapwide, a friendly and approachable website development company providing top-notch web design, mobile app development, and consulting services globally." />
            </Helmet>
            <div className='top-spacing container-fluid' id='about'>
                <div className='container about-banner'>
                    <h1>LeapWide is a software and digital company where perfect software, websites and web & mobile apps <span>come to life..</span> </h1>
                </div>
                <div className='container Impressed-main'>
                    <div className='Impressed' data-aos="fade-up">
                        <div className='Impressed-inner'>
                            <h2>Impressed?</h2>
                            <a href='/hireus'><button className='common-btn' >hire us</button></a>
                        </div>
                    </div>
                </div>
                <div className='container impactful'>
                    <h1>
                        IMPACTFUL.<br></br>
                        INSPIRING.<br></br>
                        UNIQUE.<br></br>
                        <span>POWERFUL.</span> </h1>
                    <p class="para-txt">We’re a technology company with a purpose. We believe that the best-planned experience is wasted without a top-notch implementation. Our constant pursuit is to develop supremely functional software, websites, web & mobile applications engineered for stability and performance. Our endeavor in our works is to focus on user experience, impact, usability and achieving results.
                        <br></br><br></br>We value strong client partnerships built on trust, communication, transparency, empathy, fun, and shared goals; to take you from ideation to tangible digital presence. Our approach involves understanding your short and long term vision and tailoring strategies to achieve them.</p>
                </div>
            </div>
        </>
    )
}

export default About

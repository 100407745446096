import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import "./HireUs.css";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
const HireUs = () => {
    const [value, setValue] = useState(3);
    const [budget, setBudget] = useState(5);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [project, setProject] = useState('');
    const [location, setLocation] = useState('');
    const [referred, setReferred] = useState('');
    const [whorefered, setWhoRefered] = useState('');
    const [errors, setErrors] = useState({});
    const [thankYouMessage, setThankYouMessage] = useState(''); // State for thank you message

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleBudgetChange = (event) => {
        setBudget(event.target.value);
    };

    const handleRadioChange = (event) => {
        setReferred(event.target.value);
    };

    const validate = () => {
        const errors = {};

        if (!fullName.trim()) {
            errors.fullName = "This field is required";
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.trim()) {
            errors.email = "This field is required";
        } else if (!emailRegex.test(email)) {
            errors.email = "Invalid email format";
        }

        if (!company.trim()) {
            errors.company = "This field is required";
        }

        if (!project.trim()) {
            errors.project = "This field is required";
        }

        return errors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = validate();
        if (Object.keys(errors).length === 0) {
            // Log form data to the console
            console.log("Form submitted");
            console.log("Full Name:", fullName);
            console.log("Email:", email);
            console.log("Company:", company);
            console.log("Project:", project);
            console.log("Timeline (in months):", value);
            console.log("Budget (in thousands USD):", budget);
            console.log("Location:", location);
            console.log("Referred:", referred);
            console.log("whorefered:", whorefered);
            // Send email if no errors
            const serviceId = 'service_n6yjaij';
            const templateId = 'template_5qu4e8g';
            const publicKey = 'AwzcsOsGqs4RO91KB';
            const templateParams = {
                from_name: fullName,
                from_email: email,
                from_company: company,
                from_location: location,
                to_name: 'Leapwide',
                message: project,
            };
            emailjs.send(serviceId, templateId, templateParams, publicKey)
                .then((response) => {
                    console.log('Email sent successfully!', response);
                    setFullName('');
                    setEmail('');
                    setCompany('');
                    setProject('');
                    setLocation('');
                    setReferred('');
                    setWhoRefered('');
                    setValue(3);
                    setBudget(5);
                    setErrors({});
                    setThankYouMessage('Thank you for reaching out! We have received your information and will get back to you shortly.');
                })
                .catch((error) => {
                    console.error('Error sending email=================>:', error);
                });
        }
        // return errors;
        else {
            setErrors(errors);
        }

    };

    return (
        <>
            <Helmet>
                <title>Hire Expert Web Developers & Designers | Leapwide</title>
                <meta name="description" content="Looking for a web development company? Hire Leapwide for professional website design, custom software, and mobile app development tailored to your business needs." />
            </Helmet>
            <div className='top-spacing container-fluid' id='hire-us'>
                <div className='container hire-us'>
                    <h1>LET’S START<br />
                        <span>A PROJECT</span>
                    </h1>
                    <p className='para-txt'>We help small and medium companies with forward thinking leaders</p>
                    <p className="main-head">make an impact online.</p>
                    <div className='contact-form'>
                        <p className='per-text'>Let’s talk about your requirements so we have a
                            better understanding of your needs.</p>
                        <form onSubmit={handleSubmit}>
                            <label>DID SOMEONE REFER YOU TO US?</label>
                            <div className='radio'>
                                <span>
                                    <input
                                        type="radio"
                                        id="yes"
                                        name="referral"
                                        value="YES"
                                        className='input'
                                        onChange={handleRadioChange}
                                        checked={referred === 'YES'}
                                    />
                                    <label htmlFor="yes">YES</label><br />
                                </span>
                                <span>
                                    <input
                                        type="radio"
                                        id="no"
                                        name="referral"
                                        value="NO"
                                        className='input'
                                        onChange={handleRadioChange}
                                        checked={referred === 'NO'}
                                    />
                                    <label htmlFor="no">NO</label><br />
                                </span>
                            </div>
                            <div className='input'>
                                <label>WHO REFERRED YOU?</label>
                                <input className='refered input' type='text' value={whorefered}
                                    onChange={(e) => setWhoRefered(e.target.value)}></input>
                            </div>
                            <div className='input'>
                                <label>TELL US ABOUT YOUR PROJECT <span>*</span></label>
                                <textarea
                                    placeholder="Please let us know a bit about your organization and your goals..."
                                    className={`refered input textarea  ${errors.project ? 'error-input' : ''}`}
                                    value={project}
                                    onChange={(e) => setProject(e.target.value)}
                                ></textarea>
                                {errors.project && <p className="error">{errors.project}</p>}
                            </div>
                            <div className='input input-slider'>
                                <label>YOUR TIMELINE </label>
                                <input
                                    type="range"
                                    min="1"
                                    max="24"
                                    value={value}
                                    className="slider"
                                    id="myRange"
                                    onChange={handleChange}
                                />
                                <p>Value: <span id="demo">{value}</span></p>
                            </div>
                            <div className='input input-slider'>
                                <label>YOUR BUDGET </label>
                                <input
                                    type="range"
                                    min="1"
                                    max="100"
                                    value={budget}
                                    className="slider"
                                    id="myRange"
                                    onChange={handleBudgetChange}
                                />
                                <p>(USD '000): <span id="demo">{budget}</span></p>
                            </div>
                            <p className='per-text after-slider'> If you aren't sure, just select an approximate value.</p>
                            <label className='label-bottom'>HOW CAN WE REACH YOU?</label>
                            <div className='input'>
                                <label>FULL NAME  <span>*</span></label>
                                <input
                                    className={`refered input ${errors.fullName ? 'error-input' : ''}`}
                                    type='text'
                                    placeholder='Full Name'
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                {errors.fullName && <p className="error">{errors.fullName}</p>}
                            </div>
                            <div className='input'>
                                <label>EMAIL  <span>*</span></label>
                                <input
                                    className={`refered input ${errors.email ? 'error-input' : ''}`}
                                    type='text'
                                    placeholder='Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <p className="error">{errors.email}</p>}
                            </div>
                            <div className='input'>
                                <label>ORGANIZATION/COMPANY  <span>*</span></label>
                                <input
                                    className={`refered input ${errors.company ? 'error-input' : ''}`}
                                    type='text'
                                    placeholder='Organization/Company'
                                    value={company}
                                    onChange={(e) => setCompany(e.target.value)}
                                />
                                {errors.company && <p className="error">{errors.company}</p>}
                            </div>
                            <div className='input'>
                                <label>LOCATION</label>
                                <input
                                    className='refered input'
                                    type='text'
                                    placeholder='Location'
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                />
                            </div>
                            <ReCAPTCHA
                                sitekey="6LesZYEmAAAAAAEJ-u90Ln9TIIrDNk81tUYdvShn"
                            // onChange={onChange}
                            />
                            <button type='submit'>Submit</button>
                        </form>
                        {thankYouMessage && (
                            <div className="thank-you-message">
                                <p>{thankYouMessage}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default HireUs;

// src/Preloader.js
import React from "react";
import "./Preloader.css"; // You can style your preloader here
import logo from "../../Assests/img/LeapWide-logo.png";
const Preloader = () => {
  return (
    <div className="preloader">
      <div class="loaderClass">
        <img src={logo}></img>
      </div>
    </div>
  );
};

export default Preloader;

import React from 'react';
import { Helmet } from "react-helmet";
import { OurWorkData } from "../Data";
import whiteArrow from "../../Assests/img/white-arrow.png";
import lifeupCorporateWellness1 from "../../Assests/img/LifeUpCorporateWellness/lifeupCorporateWellness1.webp";
import "./OurWork.css";
const OurWork = () => {
    return (
        <>
          <Helmet>
                <title>Our Projects | Website and App Design & Development Portfolio - Leapwide</title>
                <meta name="description" content="Explore Leapwide’s portfolio showcasing successful website design, mobile app development, and software projects for small and medium businesses across the globe." />
            </Helmet>
            <div className='top-spacing container-fluid' id='ourWork'>
                <div className='container ourWork-banner'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-8 col-lg-6'>
                            <h1>The Work.</h1>
                            <p>We make projects to bring vision into reality.</p>
                        </div>
                    </div>
                </div>
                {/* TWO SECTION START */}
                <section id='two-sections'>
                    <div className='container-fluid for-left-right-spacing ' >
                        <div className='row'>
                            {
                                OurWorkData.map((data, index) => {
                                    return (
                                        <div className='col-md-6' key={index} id={data.fullwidth} data-aos="fade-up" data-aos-delay="10">
                                            <a href={data.link}>
                                                <div class={data.class}>
                                                    <img className='bigimg' src={data.img} alt={data.alt} title={data.title} />
                                                    <div class="overlay">
                                                        <div className='overlay-all'>
                                                            <div className='top-overlay'>
                                                                <img src={whiteArrow} alt='arrow' title='arrow'></img>
                                                                <h5>PROJECT</h5>
                                                            </div>
                                                            <div className='overlay-inner'>
                                                                <div className='text-bottom'>
                                                                    <h5>{data.name}</h5>
                                                                    <p>{data.desc}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="info">
                                                        <h5>{data.name}</h5>
                                                        <p>{data.desc}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </section>
                {/* TWO SECTION END */}
            </div>
        </>
    )
}

export default OurWork

import React from 'react';
import { Helmet } from "react-helmet";
import "./ContactUs.css";
import bigImg from "../../Assests/img/bigimage-contact.webp";
import smallImg from "../../Assests/img/smallimage-contact.webp";
const ContactUs = () => {
    return (
        <>
            <Helmet>
                <title>Contact Leapwide | Get in Touch with Our Web Development Team</title>
                <meta name="description" content="Reach out to Leapwide for expert web design, mobile app development, and custom software solutions. Contact our friendly team today to start your digital project." />
            </Helmet>
            <div className='top-spacing container-fluid' id='contact-us'>
                <div className='container contact-banner' data-aos="fade-up">
                    <h1>NEED TO TALK? </h1>
                    <span>Tell us how we can help you better.</span>
                </div>
                <div className='container address'>
                    <p>India -2nd Floor, Sebiz Square<br></br>
                        Sector 67, Chandigarh<br></br>
                        <a href='tel:+919999105818'>+919999105818</a>
                    </p>
                    <p className='address-top'>USA -106, Harvard Ln,Bryn<br></br>
                        Mawr,PA 19010<br></br>
                        <a href='tel:+14843810025'>+14843810025</a>
                    </p>
                </div>
                <div className='container-fluid project-inquires'>
                    <div className='row'>
                        <div className='col-md-12 col-sm-12 col-lg-5' >
                            <img src={bigImg} alt='bigImg' title='bigImg' className='bigImg' data-aos="fade-up" data-aos-delay="10"></img>
                        </div>
                        <div className='col-md-12 col-sm-12 col-lg-7 right-side' >
                            <div className='info' data-aos="fade-up" data-aos-delay="10">
                                <h6>Project Inquiries</h6>
                                <p><a href="mailto:leapwide.com">leapwide.com</a></p>
                                <h6>Join Our Team</h6>
                                <p><a href="mailto:hiring@leapwide.com">hiring@leapwide.com</a></p>
                                <h6>For Anything Else</h6>
                                <p><a href="mailto:hello@leapwide.com">hello@leapwide.com</a></p>
                            </div>
                            <div className='smallImg'>
                                <img src={smallImg} alt='smallImg' title='smallImg' data-aos="fade-up" data-aos-delay="10" ></img>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs
